<mat-toolbar color="white" style="height: 60px" fxLayout="row">
  <div fxLayout="row" style="padding-top: 10px; padding-bottom: 10px;">

    <div fxFlex>
      <a routerLink="/">
        <div fxLayout fxFlex>
          <img src="../../../assets/img/constellation-logo.svg" alt="constellation logo"
               width="210px" height="51px">
        </div>
      </a>
    </div>
    <div fxFlex style="padding-top: 10px; padding-left: 40px;">
      <img src="../../../assets/img/energy_choice_logo.svg" alt="energy_choice_logo" width="180px" height="36px">
    </div>

  </div>

  <!-- <div id="globalSearchTextId" class="dx-field-value" *ngIf="this.authorizeService.objectPermission.Offer.read">
    <dx-number-box [(value)]="globalSearchText" placeholder="Search by number"  height="40px" class="globalSeachInputCss">
        <dxi-button  name="globalSearch" location="after" [options]="{icon: 'search', onClick: globalSearchClick}"></dxi-button>
        <dxi-button  name="globalSearchInfo" location="after" [options]="{icon: 'info', onClick: toggleDefault}"></dxi-button>
    </dx-number-box>
  </div> -->

  <div id="globalResult" class="searchResultContainer" [(hidden)]="resultVisible">
    <div class="searchResult">
      <!-- <app-offer-search [type]="'Global'" [globalSearchText]="globalSearchValue"></app-offer-search> -->
    </div>
  </div>

  <dx-tooltip target="#globalSearchTextId"
              [(visible)]="defaultVisible"
              [closeOnOutsideClick]="false">
    <div *dxTemplate="let data = data of 'content'" style="text-align: left;">
      Can be used to search for:
      <ul>
        <li>OfferId</li>
        <li>DH Customer Id</li>
        <li>DH Account Id</li>
        <li>Genesis Account Number</li>
        <li>Genesis Customer Number</li>
      </ul>
    </div>
  </dx-tooltip>

  <div fxFlex fxLayout="row" fxLayoutAlign="end">
    <div style="padding-right: 40px;">
      <!-- <span class="loggedin-user" > {{userName}}</span> -->
      <a class="loggedin-user" style="cursor: pointer; font-size: small; color: black;"
         (click)="rolesClick()">{{userName}}</a>
    </div>
    <div style="padding-right: 40px;">
      <a class="navigation-items" style="cursor: pointer; font-size: small;"
         (click)="onLogOutButtonClick()">Logout</a>
    </div>
  </div>
  <span id="spacer"></span>
</mat-toolbar>
