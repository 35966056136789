import { protectedResources } from "src/app/auth-config";


export const environment = {
  production: false,

  apiEndpoint: "https://exodus-stage-functions.azurewebsites.net/",
  scopes: ["api://64eab638-d89c-43be-93bc-9a9cd9ceb47d/Access_to_CommissionApp"],
  redirectUri: 'https://exodus-commissionapplication-stage.constellation.com/',
  postLogoutRedirectUri: 'https://cdn-exodus-stage.azureedge.net/#/log-out',
  clientid: '8f1b4246-f0fd-45e6-9377-286dfcba1ec1',
  authority: 'https://login.microsoftonline.com/d8fb9c07-c19e-4e8c-a1cb-717cd3cf8ffe',
  instrumentationKey: '68d7dcdd-d579-4570-a79d-2dbcee9dd15e',
  AppInsightApiId: '35d8cb73-420f-4478-9f9a-c0b5f7f044fb',
  AppInsightApiKey: 'vctu7ih14btksmx4v1jeh6knvf4u28oeovr2u17h',
  defaultenv:"Stage",
  Settings: {
    AllowEditResponse: true,
    EnvironmentBoxText: 'LOCAL',
    EnvironmentBoxBG: 'environment-box-bg-local'
  },




}
